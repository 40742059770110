<script>
export default {
  render() {
    return this.$scopedSlots.default({
      selected: this.selected,
      isSelected: this.isSelected,
      select: this.select,
      clearSelected: this.clearSelected
    })
  },

  data: () => ({
    selected: []
  }),

  computed: {
    isSelected() {
      return this.selected.length
   
    },
  },

  methods: {
    select(val) {
      let index = this.selected.findIndex(item => item.ID === val.ID)
      if (index <= 0) this.selected.push(val)
      else this.selected.splice(index, 1)
      
    },

    clearSelected() {
      this.selected = []
    }
  }
}
</script>